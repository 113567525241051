import clearAnalyticsEvent from '../utilities/clearAnalyticsEvent';
import {
    ANALYTICS_SORT_ORDER,
    ANALYTICS_NULL_VALUES,
    ANALYTICS_EVENT,
    ANALYTICS_VIEW,
} from './constants';

const useSearchResultClickAnalytics = (
    searchResultClickObject,
    searchParams,
    searchParamKeys,
    searchClickCategory,
    customAnalyticObject,
    customAnalyticFields
) => {
    clearAnalyticsEvent(ANALYTICS_EVENT.SEARCH_RESULT_CLICK);

    const gtmResultCard = {
        event: ANALYTICS_EVENT.SEARCH_RESULT_CLICK,
        searchInfo: {
            searchTerm:
                searchParams[searchParamKeys.SEARCH_QUERY] ||
                ANALYTICS_NULL_VALUES.NA_VALUE,
            location:
                searchParams[searchParamKeys.ADDRESS_QUERY] ||
                ANALYTICS_NULL_VALUES.NA_VALUE,
            searchCategory: searchClickCategory,
            searchResultsCount: searchResultClickObject.searchResultsCount,
            searchSort:
                searchParams[searchParamKeys.SORT_ORDER] ||
                ANALYTICS_SORT_ORDER.MOST_RELEVANT,
            searchView: ANALYTICS_VIEW.LIST,
            ...customAnalyticFields,
        },
        resourceInfo: searchResultClickObject.resourceInfo,
        clickedResult: searchResultClickObject.clickedResult,
        filterInfo: searchResultClickObject.filterInfo,
        ...customAnalyticObject,
    };

    window.dataLayer.push(gtmResultCard);
};

useSearchResultClickAnalytics.defaultProps = {
    customAnalyticObject: {},
    customAnalyticFields: {},
};

export default useSearchResultClickAnalytics;
